export const orangeColorPalette = {
  /*'amber': '#FFBF00',
  'apricot': '#FBCEB1',
  'brightOramge': '#FFAC1C',
  'burntSienna': '#E97451',
  'coral': '#FF7F50',
  'goldenYellow': '#FFC000',
  'lightBg': '#FFD580',
  'mahogany': '#C04000',
  'mango': '#F4BB44',
  'neonOrange': '#FF5F1F',
  'orange': '#FFA500',
  'pastelOrange': '#FAC898',
  'peach': '#FFE5B4',*/
  'lightBg':'#FCC16D',
  'otherLightBg':'#F9B051',
  'darkBg': '#E17048',
  'pink': '#F69B90'
};
